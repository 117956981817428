import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle, theme } from '../theme';

interface BaseProps {
  children: ReactNode;
}

export const Base = ({ children }: BaseProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Base;
