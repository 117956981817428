const colors = {
  primary: {
    aqua: {
      light: '#C9F0EE',
      normal: '#51D1C8',
      midi: '#119A90',
      dark: '#0D5C61',
    },
    black: '#212625',
  },
  surface: {
    graySurface: '#FCFCFC',
    white: '#FFFFFF',
  },
  gray: {
    100: '#373F3D',
    80: '#545E5C',
    60: '#9A9F9E',
    40: '#D7D9D8',
    30: '#D4D6D8',
    20: '#EBECEC',
    10: '#F8F8F8',
  },
  status: {
    confirmation: '#22D674',
    error: '#F9473C',
    pending: '#FFCE50',
    light: '#FEE4E2',
  },
};

export const colorsLight = {
  linearGradientMain: 'linear-gradient(90deg, #0FC6B8 0%, #60DFD6 100%)',
  simpleWhite: colors.surface.white,
  lightGray: colors.gray[10],
  thirdLightGray: colors.gray[30],
  grayLink: colors.gray[40],
  mainGray: colors.gray[60],
  secondaryGray: colors.gray[80],
  grayBlack: colors.gray[100],
  mainBlack: colors.primary.black,
  statusConfirmation: colors.status.confirmation,
  statusError: colors.status.error,
  statusPending: colors.status.pending,
  statusErrorLight: colors.status.light,
  midiAquaColor: colors.primary.aqua.midi,
  mainAguaColor: colors.primary.aqua.normal,
  lightAquaColor: colors.primary.aqua.light,
  darkAquaColor: colors.primary.aqua.dark,
};