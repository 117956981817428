import { createGlobalStyle } from 'styled-components';
import { colorsLight } from './colors';

export const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1400px',
}

export const theme = {
  colors: colorsLight,
  breakpoints,
};

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Manrope';
    font-style: normal;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    list-style-type: none;
  }
  h1 {
    font-size: 64px;
    line-height: 70px;
  }
  h2 {
    font-size: 44px;
    line-height: 50px;
  }
  h3 {
    font-size: 30px;
    line-height: 36px;
  }
  h4 {
    font-size: 24px;
    line-height: 30px;
  }
  h5 {
    font-size: 20px;
    line-height: 26px;
  }
  h6 {
    font-size: 14px;
    line-height: 26px;
  }
`;