import * as React from "react"
import type { HeadFC } from "gatsby"
import styled from 'styled-components';

import Base from "../../../layout/Base"

import { colorsLight } from "../../../theme/colors";

const Container = styled.div`
  display: flex;
  margin: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Background = styled.div<{color: string }>`
  background-color: ${({theme, color}) => theme.colors[color]};
  width: 300px;
  height: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007e80;
  font-size: 18px;
  font-weight: 900;
`;

const Colors = () => {
  const keys = Object.keys(colorsLight);
  return (
    <Base>
      <h1>Colors</h1>
      <Container>
        {keys.map(k => {
          return <Background color={k}>{k}</Background>;
        })}
      </Container>
    </Base>
  )
}

export default Colors

export const Head: HeadFC = () => <title>Colors</title>
